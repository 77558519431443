import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/wotfard/wotfard-medium-webfont.ttf';
import './fonts/wotfard/wotfard-regular-webfont.ttf';
import './fonts/wotfard/wotfard-light-webfont.ttf';
import './fonts/wotfard/wotfard-semibold-webfont.ttf';
import './fonts/wotfard/wotfard-bold-webfont.ttf';
import './fonts/based/Based-Regular.22ef1e41.woff2';
import App from './App';

ReactDOM.render(
  <App />, document.getElementById('root')
); 


