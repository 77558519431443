import React, { useState, useEffect } from "react";
import { request, gql } from "graphql-request";
import {
  Box,
  Wrap,
  WrapItem,  
  Heading,
  Image,
  Text,
  HStack,
  Link,
} from '@chakra-ui/react'
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Draggable from 'react-draggable';
import './projects.css'

const graphcms =
    "https://api-ap-south-1.graphcms.com/v2/cl4mpujwl7y9a01xv80jxacfs/master";

const QUERY = gql`
{
  projects {
    title,
    tags,
    description,
    projectImage {
      url
    },
    projectLink
  }
}
`

// class Projects extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "projects",
//     };
//   }


//   onTrigger = (event) => {
//     this.props.parentCallback(false, "showHideProjects");
//     event.preventDefault();
//   }

//   onTriggerTwo = (event) => {
//     this.props.parentCallbackTwo(0, 1, 0, 0);
//     event.preventDefault();
//   }

//   render() {
//     return (
//       <Draggable handle="#titlebar" bounds="body">

//         <div id="projects_window" onClick={this.onTriggerTwo} style={{zIndex: this.props.data}}>

//           <header id="titlebar">

//             <div id="window-title">
//               <span><b>Projects.exe</b></span>
//             </div>

//             <div id="window-controls">
//               <div className="button" id="close-button" title="Close" onClick={this.onTrigger}>
//               </div>
//             </div>

//           </header>

//           {/* <div id="top_border_projects">
//             <p>Projects.exe</p>
//           </div>
//           <button id="close_btn" onClick={this.onTrigger}>
//                &#x2715;
//             </button> */}
//           <div id="content">
//           </div>
//         </div>
//       </Draggable>
//     );
//   }
// }

const Projects = (props) => {
  const[window, setWindow] = useState('windowed');
  const[isMax, setMax] = useState(false);
  const [projects, setProjects] = useState(null);
  const [x, setX]= useState(0);
  const [y, setY]= useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [originalY, setOriginalY] = useState(0);

    useEffect(() => {
        const fetchProjects = async () => {
          const { projects } = await request(
                graphcms, QUERY
            );
          setProjects(projects);
        };
    
        fetchProjects();
    }, []);

  function onTrigger(event) {
    props.parentCallback(false, "showHideProjects");
    event.preventDefault();
  }

  function onTriggerMax(event) {
    if(isMax === false) {
      setX(0);
      setY(0);
      setOriginalX(x);
      setOriginalY(y);
      setWindow('fullscreen');
      setMax(true);
    } else {
      setWindow('windowed');
      setX(originalX);
      setY(originalY);
      setMax(false);
    }
    event.preventDefault();
  }

  function onTriggerTwo(event) {
    props.parentCallbackTwo(0, 1, 0, 0, 0);
    event.preventDefault();
  }

  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
    event.preventDefault();
  };

  function ProjectCard({ title, tags, desc, image, ...rest }) {
    return (
      <Box 
        p={5}
        bg='#0C0C0C'
        color='white'
        shadow='md'
        border='1px solid #647D87'
        borderRadius='4px'
        minWidth='200px'
        maxWidth='300px'
      >
        <Image
          h='200px'
          w='100%'
          objectFit='cover'
          src={image}
          alt={title}
        />
        <HStack
          paddingInline={8}
          paddingBlock={4}
        >
          <Wrap>
          {tags.map((data, index) =>(
            <WrapItem key={index}>
            <Box
              border='1px solid #00bfa5'
              borderRadius='4px'
              paddingInline={12}
              paddingBlock={6}
            >
              <Text
                m={0}
                fontSize='16px'
                fontFamily='WotfardSemi'
                color='#00bfa5'
              >{data}</Text>
            </Box>
            </WrapItem>
          ))}
          </Wrap>
        </HStack>
        <Heading fontSize='xl' p={4} color='#e9ebf1'>{title}</Heading>
        <Text mt={4} p={4} fontSize='16px' fontFamily='WotfardRegular' color='#cbcedd'>{desc}</Text>
      </Box>
    )
  }

  return (
    <Draggable 
      handle="#titlebar"
      bounds="body"
      cancel="#window-controls"
      onStop={handleStop} 
      position={{x: x, y: y}}
    >

      <div id="projects_window" className={window} onClick={onTriggerTwo} style={{zIndex: props.data, transition: 'all 0.2s ease'}}>

        <header id="titlebar">

          <div id="window-title">
            <span><b>PROJECTS</b></span>
          </div>

          <div id="window-controls">
            <div className="button" id="close-button" title="Close" onClick={onTrigger}>
            </div>
            <div className="button" id="max-button" title="Maximize" onClick={onTriggerMax}>
            </div>
          </div>

        </header>

        <Box
          w='100%'
          h='calc(100% - 30px)'
          mt='30px'
          overflow='scroll'

          bg='black'
          background="repeating-linear-gradient(
            0deg,
            transparent,
            transparent 19px,
            rgba(0, 191, 165, 0.2) 20px
          ),
          repeating-linear-gradient(
            90deg,
            transparent,
            transparent 19px,
            rgba(0, 191, 165, 0.2) 20px
          )"

          sx={
            { 
           '::-webkit-scrollbar':{
                  display:'none'
              }
           }
         }
        >
          <Router>
              {!projects ?  (
                'Loading'
              ) : (
                <Wrap
                  spacing='20px'
                  p='20px'
                >
                    {projects.map(({title, tags, description, projectImage, projectLink}, index) => (
                      //<Link onClick={console.log('clicked!')} /*to={{ pathname: "https://github.com" }} target='_blank' */ style={{textDecoration: "none"}} href='https://chakra-ui.com' isExternal> 
                        <WrapItem key={index} >
                          <ProjectCard
                            title={title}
                            tags={tags}
                            desc={description}
                            image={projectImage.url}
                          />
                        </WrapItem>
                      //</Link>
                    ))}
                </Wrap>
              )}
              </Router>
      </Box>
      </div>
    </Draggable>
  );
}

export default Projects;

// rgba(0, 191, 165, 0.1) - green dark
// #0C0C0C - card black
// #647D87 - card border