import React from "react";
import { Component } from "react";
import {
    Text, Image, Box,
    Grid, GridItem, Center,
} from '@chakra-ui/react'
import './loading.css'

import loading_image from "../assets/images/loading_gif.gif"

class Loading extends Component {

    constructor(props) {
        super(props);
        this.state= {
            loadingText: 'BOOTING',
            randomNums: '000000',
            randomNumsTwo: '8352',
        };
    }

    componentDidMount() {
        this.startLoadingAnimation();
        setTimeout(() => {
            this.props.onLoadingComplete();
        }, 3600);
    }

    startLoadingAnimation() {
        const interval = setInterval(() => {
            this.setState((prevState) => ({
                randomNums: this.generateNums(1),
                randomNumsTwo: this.generateNums(2),
            }));
        }, 200);

        setTimeout(() => {
            clearInterval(interval);
            this.setState({
                loadingText: 'WE ARE IN',
                randomNums: '999999',
                randomNumsTwo: '9999',
            });
        }, 3100);
    }

    generateNums(val) {
        if(val === 1) {
            return Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
        } else {
            return Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        }
    }

    render() {
        const {loadingText, randomNums, randomNumsTwo} = this.state;

        return (
            <Grid
                w="100%" h="100vh"
                backgroundColor="#0F0F0F"
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(5, 1fr)'
            >
                <GridItem colSpan={3} rowSpan={1} padding="10%">
                    <Text
                        fontFamily='BasedPixel' fontSize="20px"
                        color='#48A39C'
                    >
                        THYME OS - HIGH PERFORMANCE 2.012.67 LTS <br/>
                        PERFORMANCE BUILD 8010 : MTX {randomNumsTwo}
                    </Text>
                    <Text
                        fontFamily='BasedPixel' fontSize="20px"
                        color='#48A39C'
                    >
                        INITIALIZING SYSTEM <br/>
                        RANDOMIZING PARAMETERS : {randomNums}
                    </Text>
                    <Text
                        fontFamily='BasedPixel' fontSize="20px"
                        color='#48A39C'
                    >
                        CENT PROC : VIMEX XA1Z <br/>
                        SHELL : XTERM JS / LOCAL ECHO
                    </Text>
                    <Text
                        fontFamily='BasedPixel' fontSize="20px"
                        color='#48A39C'
                    >
                        {loadingText}
                    </Text>
                </GridItem>
                <GridItem className="loading-animation" colSpan={2} rowSpan={1} paddingBlock="20%" paddingInline="25%">
                    <Center><Image src={loading_image} alt='THYME' h="75%" w="auto" /></Center>
                    {/* <Text textAlign="center" fontFamily='BasedPixel' fontSize="22px" color='#F4CE14' mb={4}> */}
                    <Text textAlign="center" fontFamily='BasedPixel' fontSize="22px" color='#F4CE14' mb={4} bg="#2D3748" rounded="2px">
                        NEXT LEVEL
                    </Text>
                    {/* <Text textAlign="center" fontFamily='BasedPixel' fontSize="16px" fontWeight="bold" m={2} color='#16FF00' borderTop="1px solid" borderColor="#16FF00"> */}
                    <Text textAlign="center" fontFamily='BasedPixel' fontSize="18px" fontWeight="bold" m={2} color='#000000' p="2px" rounded="2px" bg="#16FF00">
                        GEARED UP MEMORY
                    </Text>
                    <Text textAlign="center" fontFamily='BasedPixel' fontSize="18px" m={2} color='#84a0c6' p="2px" rounded="2px" bg="#161821">
                        PERFORMANCE BUILD
                    </Text>
                </GridItem>
            </Grid>
        );
    }
}

export default Loading;