import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Center,
    Grid,
    GridItem,
    VStack,
  } from '@chakra-ui/react';
import Draggable from 'react-draggable';

const Credits = (props) => {
    
    function onTrigger(event) {
        props.parentCallback(false, "showHideCredits");
        event.preventDefault();
      }

    function onTriggerTwo(event) {
        props.parentCallbackTwo(0, 0, 0, 0, 1);
        event.preventDefault();
      }

    return(
        <Draggable 
            handle="#creditTopBar"
            bounds="body"
            cancel="#creditWindowControl"
        >
            <Box 
                w='300px'
                h='300px'
                bg='#393E46'
                top='20%'
                left='20%'
                position='absolute'
                border='2px solid black'   
                borderRadius='4px'
                outline='2px solid black'
                outlineOffset='-6px' 
                opacity='0.9'
                boxShadow='5px 10px #0C0C0C'
                onClick={onTriggerTwo}
                style={{zIndex: props.data}}           
            >
                <Box
                    id='creditTopBar'
                    w='100%'
                    h='25px'
                    bg='transparent'
                >
                    <Grid
                        w='100%'
                        h='100%'
                        templateColumns='repeat(6, 1fr)'
                    >
                    <GridItem
                        colSpan={1}
                        cursor='move'
                    ></GridItem>
                    <GridItem
                        colSpan={4}
                        h='25px'
                        cursor='move'
                    >
                    <Center
                        w='100%'
                        h='100%'
                    >
                    <Text
                        fontSize='16px'
                        fontFamily='BasedPixel'
                    >
                        CREDITS
                    </Text>
                    </Center>
                    </GridItem>
                    <GridItem
                        id='creditWindowControl'
                        colSpan={1}
                        h='25px'
                        onClick={onTrigger}
                    >
                        <Center
                        w='100%'
                        h='100%'
                    >
                    <Text
                        fontSize='16px'
                        fontFamily='BasedPixel'
                        fontWeight='bold'
                        _hover={{
                            color: 'blue',
                        }}
                    >
                        X
                    </Text>
                    </Center>
                    </GridItem>
                    </Grid>
                </Box>
                {/* <Box
                    w='calc(100% - 10px)'
                    h='calc(100% - 30px)'
                    ml='5px'   
                    bg='yellow'
                    fontSize='14px'
                    fontFamily='BasedPixel'
                    fontWeight='semibold'
                >
                </Box> */}
                <VStack 
                    align='stretch'
                    spacing={0}
                    fontSize='15px'
                    fontFamily='BasedPixel'
                    fontWeight='semibold'
                    ml='10px'
                >
                    <Text>
                        WEBPAGE
                        <br />
                        Built using React, Three and Chakra.
                    </Text>
                    <Text>
                        BIG BRAIN
                        <br />
                        R. Dunk <br />Pewdiepie
                    </Text>

                </VStack>
            </Box>

        </Draggable>
    );
}


export default Credits;