import React, { useState } from "react";
import { Grid, GridItem, Box, Center, Text, VStack, Link, StackDivider, UnorderedList, ListItem, HStack, Img } from "@chakra-ui/react";
import Draggable from 'react-draggable';
import './skills.css'

// class Skills extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "skills",
//     };
//   }

//   onTrigger = (event) => {
//     this.props.parentCallback(false, "showHideSkills");
//     event.preventDefault();
//   }

//   onTriggerMax = (event) => {
//     event.preventDefault();
//   }


//   onTriggerTwo = (event) => {
//     this.props.parentCallbackTwo(1, 0, 0, 0);
//     event.preventDefault();
//   }

//   render() {
//     return (
//       <Draggable handle="#titlebar" bounds="body">

//         <div id="skills_window" onClick={this.onTriggerTwo} style={{zIndex: this.props.data}}>

//           <header id="titlebar">

//             <div id="window-title">
//               <span><b>Skills.exe</b></span>
//             </div>

//             <div id="window-controls">
//               <div className="button" id="max-button" title="Maximize" onClick={this.onTriggerMax}>
//               </div>
//               <div className="button" id="close-button" title="Close" onClick={this.onTrigger}>
//               </div>
//             </div>

//           </header>

//           {/* <div id="top_border_skills">
//             <p>Skills.exe</p>
//           </div>
//             <button id="close_btn" onClick={this.onTrigger} alt="close">
//                &#x2715;
//             </button> */}
//           <div id="content_skills">
//             <ul className="list">
//               <li>Python</li>
//               <li>C</li>
//               <li>React</li>
//               <li>Node</li>
//               <li>Java</li>
//               <li>Android Studio</li>
//             </ul>
//           </div>
//         </div>
//       </Draggable>
//     );
//   }
// }

const Skills = (props) => {
  const[window, setWindow] = useState('windowed');
  const[isMax, setMax] = useState(false);
  const [x, setX]= useState(0);
  const [y, setY]= useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [originalY, setOriginalY] = useState(0);

  function onTrigger(event) {
    props.parentCallback(false, "showHideSkills");
    event.preventDefault();
  }

  function onTriggerMax(event) {
    if(isMax === false) {
      setX(0);
      setY(0);
      setOriginalX(x);
      setOriginalY(y);
      setWindow('fullscreen');
      setMax(true);
    } else {
      setX(originalX);
      setY(originalY);
      setWindow('windowed');
      setMax(false);
    }
    event.preventDefault();
  }

  function onTriggerTwo(event) {
    props.parentCallbackTwo(1, 0, 0, 0, 0);
    event.preventDefault();
  }

  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
    event.preventDefault();
  };

  return (
    <Draggable 
      handle="#titlebar"
      bounds="body"
      cancel="#window-controls"
      onStop={handleStop} 
      position={{x: x, y: y}}
    >

      <div id="skills_window" className={window} onClick={onTriggerTwo} style={{zIndex: props.data}}>

        <header id="titlebar">

          <div id="window-title">
            <span><b>ABOUT</b></span>
          </div>

          <div id="window-controls">
            <div className="button" id="max-button" title="Maximize" onClick={onTriggerMax}>
            </div>
            <div className="button" id="close-button" title="Close" onClick={onTrigger}>
            </div>
          </div>

        </header>

        <Grid
          h='calc(100% - 30px)'
          mt='30px'
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(4, 1fr)'
          overflowY='scroll'
          sx={
            { 
              '::-webkit-scrollbar':{
                width: '6px'
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#C7B7A3",
                borderRadius: "8px",
              },
           }
         }
        >
          <GridItem 
            colSpan={4} bg='#F2F1EB' px='15%'
          >
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={4}
              align='stretch'
            >
              <HStack justifyContent='space-between' align='baseline'>
                <Text paddingTop='5%' fontSize='2.5rem' fontWeight='bold' fontFamily='Roboto, sans-serif' color='#333' lineHeight='1.2rem'>
                  Dhiraj Patil
                </Text>
                <HStack>
                  <Center border='2px solid #777' rounded='6px' p={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"><path d="M21 7v10c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V7c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.5 4.5v2c0 1.1.9 2 2 2h2M8 13h4M8 17h8" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </Center>
                  <Center border='2px solid #777' rounded='6px' p={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"><path d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2.67 18.95l4.93-3.31c.79-.53 1.93-.47 2.64.14l.33.29c.78.67 2.04.67 2.82 0l4.16-3.57c.78-.67 2.04-.67 2.82 0L22 13.9" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </Center>
                </HStack>
                </HStack>
              <HStack backgroundColor='#ddd' rounded='4px' w='100%' fontFamily='Roboto, sans-serif'>
                <Text fontSize='2rem' paddingStart={10}>📱</Text>
                <VStack w='100%' align='start'>
                  <span><strong>Email</strong>: dhiraj@dhiraj.xyz </span>
                  <span>
                    <Link href="https://x.com/feuledByVadapav" color='#0F0F0F' isExternal>X</Link> | <Link href="https://www.linkedin.com/in/dhiraj-patil-6b636a1b5/" color='#0F0F0F' isExternal>Linkedin</Link>
                  </span>
                </VStack>
              </HStack>
              <Text
                fontSize='1.2rem'
                fontFamily='Roboto, sans-serif'
                color='#333'
                lineHeight='2rem'
              >
                I’ve been programming since 2018 or so, and I try to have a somewhat public profile on the things I do.
                i'm interested in building my own products mainly.
                In the time after, i've been fortunate enough to work with and learn from incredible 
                founders/entrepreneurs around the world - which has seeded a lot in my work and thinking.
                I do my best work when working with ambitious, mission-driven teams.
              </Text>
              <Text
                fontSize='1.2rem'
                fontFamily='Roboto, sans-serif'
                color='#333'
                lineHeight='2rem'
              >
                Previously I was working on <Link href="https://newtrun.com/" color='#0F0F0F' isExternal>Newtrun</Link> to accelerate everything with software.
              </Text>
              <Text
                fontSize='1.2rem'
                fontFamily='Roboto, sans-serif'
                color='#333'
                lineHeight='2rem'
              >
                This website will always be a work in progress. I sometimes write blogs which are posted here, 
                but now I spend most of my time working on other things. I plan to start writing again, 
                mostly to capture a trend of changes in my thinking.
              </Text>
              {/* <Text
                fontSize='1rem' fontWeight='bold'
                fontFamily='Roboto, sans-serif'
                color='#0F0F0F'
              >
                inspired by few <Link href="https://nat.org/" fontWeight='normal' color="#0F0F0F" isExternal>people</Link> on the internet, some things i believe:
              </Text>
              <UnorderedList
                paddingInline={2}
                fontSize='1rem'
                fontFamily='Roboto, sans-serif'
                color='#0F0F0F'
                lineHeight='26px'
              >
                <ListItem color='#A0AEC0'>
                <Text as='span' color='#0F0F0F'>
                  speed is intelligence.
                  </Text>
                  <UnorderedList p={8}>
                    <ListItem>
                    <Text as='span' color='#0F0F0F'>
                      as a young person, your rate of progress is the best vector to measure growth and potential. 
                      while a lot of things in life can be thought of as chess, 
                      early on, you're almost always better off thinking of it as a race. 
                      inaction is just missed shots.
                      </Text>
                    </ListItem>
                    <ListItem pt={12} color='#A0AEC0'>
                    <Text as='span' color='#0F0F0F'>
                      a lot has been said over the years about speed in the context of startups, but every week, i re-assess our speed internally. 
                      the faster you ship, the faster you learn, the faster you refine direction.
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </ListItem>

                <ListItem pt={12} color='#A0AEC0'>
                  <Text as='span' color='#0F0F0F'>
                  startups
                  </Text>
                  <UnorderedList p={8}>
                    <ListItem color='#A0AEC0'>
                      <Text as='span' color='#0F0F0F'>
                      Having worked in a few and starting one of my own has given me learnings which only helps
                      to asses how things have changed and what all it takes to start a venture from scratch.
                      How knowledge would've helped lots only if I wasn't diving in head-first into something
                      which was just unkown to me. <Text as='span' fontSize='0.9rem' fontStyle='italic' color="#777">Plan (everything) before moving ahead!</Text>
                      </Text>
                    </ListItem>
                    <ListItem pt={12} color='#A0AEC0'>
                      <Text as='span' color='#0F0F0F'>
                      Being a young technical member, it is better to focus on building exceptional software/product
                      and taking advantage of it instead of picking battles with existing tech. Creating software has
                      never been easier. Understanding which tech to use and how to use it is the key. 
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </ListItem>

                <ListItem pt={12} color='#A0AEC0'>
                <Text as='span' color='#0F0F0F'>
                 people
                 </Text>
                  <UnorderedList p={8}>
                    <ListItem color='#A0AEC0'>
                      <Text as='span' color='#0F0F0F'>
                        Be kind, be direct, be transparent. life's short.
                      </Text>
                    </ListItem>
                    <ListItem pt={12} color='#A0AEC0'>
                      <Text as='span' color='#0F0F0F'>
                        Find someone who will take you forward with them.
                      </Text>
                    </ListItem>
                    <ListItem pt={12} color='#A0AEC0'>
                      <Text as='span' color='#0F0F0F'>
                        Moments are fleeting, people endure.
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </ListItem>

              </UnorderedList> */}
              <Text
                fontSize='1rem' fontStyle='italic'
                fontFamily='Roboto, sans-serif' fontWeight='bold'
                color='#000' paddingBottom={80}
              >
                show the world how software works for you.
              </Text>
            </VStack>
          </GridItem>
        </Grid>

        {/* People

          Be kind, be direct, be transparent and mainly be forgiving. They are living the same life you are for the 
          first time.
          Find people who will take you forward with them.

          
          What motivates me?
          
          To be honest, I dont know. I have not idea how to put it. it's the dreams i've had as a kid, promises i've
          made myself along the way. there are few things in life you dont why but you just want to do it. This is one of them.

          
          <div id="content_skills">
            {/* <ul className="list">
              <li>Python</li>
              <li>C</li>
              <li>React</li>
              <li>Node</li>
              <li>Java</li>
              <li>Android Studio</li>
            </ul> 
            <div id="content_skills_header">
              <h1>Hi, I'm Dhiraj :D</h1>
              <img src={img_charm} width={140} height={125} alt="happy charmander" />  
            </div>
            <div id="content_skills_info">
              <p>
                This is my personal website, where I write blog posts regularly,
                and keep a tab on all the projects I’ve worked on.
              </p>
              <p>
                I’ve also been programming since 2017 or so, and I try to have a somewhat public profile on the things I do.
                Topics I’ve enjoyed here include Functional Programming, Web Programming, Distributed Programming, and Programming Languages.
                I’ve programmed in a plethora of languages, from C, to Haskell, Rust, Go, Scala, Java, Python, etc.
                having written small to large-ish projects in each of them.
              </p>
            </div>
          </div>
        */}

      </div>
    </Draggable>
  );
}

export default Skills;
