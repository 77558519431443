
import React, { Component } from "react";
import { 
  Box, Center, Image, Text, 
  VStack, HStack, Wrap, WrapItem, Grid, GridItem,
  Tooltip,
} from "@chakra-ui/react";
import * as THREE from "three";
import Loading from "./components/loading.jsx";
import Skills from "./components/Skills.jsx";
import Projects from "./components/Projects";
import logo_skills from "./components/skills.png"
import logo_project from "./components/project_img.png"
import logo_contact from "./components/contact.png"
import logo_blogs from "./components/blogs_img.png";
// import logo_blogs1 from "./components/logo_blogs1.png";
import About from "./components/About";
import Blogs from "./components/Blogs.jsx";
import Credits from "./components/Credits.jsx";
//import './plugins/InfiniteGridHelper';
import './App.css';

class App extends Component {

 constructor() {
    super();
    this.state = {
      name: "React",
      posts: [],
      currentTime: new Date(),
      loadingComplete: false,
      showHideSkills: false,
      showHideProjects: false,
      showHideAbout: false,
      showHideBlogs: false,
      showHideCredits: false,
      showMenu: false,
      refMenu: React.createRef(),
      refMenuCard: React.createRef(),
      z_skills: 0,
      z_about: 0,
      z_projects: 0,
      z_blogs: 0,
      z_credits: 0,
    };
     this.hideComponent = this.hideComponent.bind(this);
     this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleLoadingComplete = () => {
    this.setState({
      loadingComplete: true,
    });
  
      //handle outside click listener
      document.addEventListener("mousedown", this.handleClickOutside);
  
      // THREE JS SCENE
      var scene = new THREE.Scene();
      var cameraCenter = new THREE.Vector3();
      var cameraHorzLimit = 3;
      var cameraVertLimit = 3;
      var mouse = new THREE.Vector2();
        scene.background = new THREE.Color( 0x23272a );
  
        var camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 500);
        camera.position.set(0, 20, 200);
        camera.lookAt(scene.position);
  
        var renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        document.body.appendChild(renderer.domElement);

        //set up mouse stuff
        document.addEventListener('mousemove', onDocumentMouseMove, false);
  
        //GRIDS
        const hex = 0xF2F2F2;
        const color = new THREE.Color(hex);
        const size = 8;
        const distance = 350;
        
        const gridgeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
        const gridmaterial = new THREE.ShaderMaterial({
          side: THREE.DoubleSide,
    
          uniforms: {
            uTime: {
              value: 0,
            },
            uSize: {
              value: size,
            },
            uColor: {
              value: color,
            },
            uDistance: {
              value: distance,
            },
          },
          transparent: true,
          vertexShader: `
          varying vec3 worldPosition;
          uniform float uDistance;
          void main() {
            vec3 pos = position.xzy * uDistance;
            pos.xz += cameraPosition.xz;
            worldPosition = pos;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
          }`,
    
          fragmentShader: `
          varying vec3 worldPosition;
          uniform float uSize;
          uniform float uTime;
          uniform vec3 uColor;
          uniform float uDistance;
          float getGrid(float size) {
            vec2 r = worldPosition.xz / size;
            vec2 gridOne = abs(fract(r - 0.5) - 0.5) / fwidth(r);
            vec2 gridTwo = abs(fract(r - uTime) - 0.5) / fwidth(r);
            float line = min(gridOne.x, gridTwo.y);
            return 1.0 - min(line, 1.0);
          }
          void main() {
            float d = 1.0 - min(distance(cameraPosition.xz, worldPosition.xz) / uDistance, 1.0);
            float grid = getGrid(uSize);
            float alpha = grid * pow(d, 3.0);
            gl_FragColor = vec4(uColor.rgb, alpha);
            gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, grid);
            if ( gl_FragColor.a <= 0.0 ) discard;
          }`,
    
          extensions: {
            derivatives: true,
          },
        });
  
        const grid = new THREE.Mesh(gridgeometry, gridmaterial);
        const grid_two = new THREE.Mesh(gridgeometry, gridmaterial);
  
        grid_two.position.set(0, 30, 0);
  
        scene.add(grid);
        scene.add(grid_two);
  
        var clock = new THREE.Clock();
        let time = 0;
  
        //CUBE - 
        var cube = new THREE.Mesh(
          new THREE.BoxGeometry( 12, 12, 12 ),
          [
            new THREE.MeshBasicMaterial( { color: 0x80BCBD }),
            new THREE.MeshBasicMaterial( { color: 0xAAD9BB }),
            new THREE.MeshBasicMaterial( { color: 0xD5F0C1 }),
            new THREE.MeshBasicMaterial( { color: 0xF9F7C9 }),
            new THREE.MeshBasicMaterial( { color: 0xD9EDBF }),
            new THREE.MeshBasicMaterial( { color: 0xDCFFB7 }),
          ]
        );	
        scene.add( cube );
        cube.position.set(0, 15, 125);
  
        render();
  
        function render() {
          cube.rotation.x += 0.01;
          cube.rotation.y += 0.01;
  
          requestAnimationFrame(render);
          updateCamera();
          time += clock.getDelta();
          grid.material.uniforms.uTime.value = time;
          grid_two.material.uniforms.uTime.value = time;
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.render(scene, camera);
        }
  
        function updateCamera() {
          //offset the camera x/y based on the mouse's position in the window
          camera.position.x = cameraCenter.x + (cameraHorzLimit * mouse.x);
          camera.position.y = cameraCenter.y + 15 + (cameraVertLimit * mouse.y);
        }
        
        function onDocumentMouseMove(event) {
          event.preventDefault();
          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        }
  
        if (window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', function (eventData) {      
  
              const beta = Math.max(Math.min(eventData.beta, 120), 0);
              const yPos = (beta - 45) / 20; // -180, 180
              if (beta > 85 && beta < 95) return;
              const xPos = eventData.gamma / 20; // -90, 90
              mouse.x = xPos
              mouse.y = -yPos
            }, false);
        }
  };

   hideComponent(name) {
    switch (name) {
      case "showHideSkills":
        this.setState({ showHideSkills: !this.state.showHideSkills, z_skills: 1, z_projects: 0, z_about: 0, z_blogs: 0, z_credits: 0 });
        break;
      case "showHideProjects":
        this.setState({ showHideProjects: !this.state.showHideProjects, z_skills: 0, z_projects: 1, z_about: 0, z_blogs: 0, z_credits: 0 });
        break;
      case "showHideAbout":
        this.setState({ showHideAbout: !this.state.showHideAbout, z_skills: 0, z_projects: 0, z_about: 1, z_blogs: 0, z_credits: 0 });
        break;
      case "showHideBlogs":
        this.setState({ showHideBlogs: !this.state.showHideBlogs, z_skills: 0, z_projects: 0, z_about: 0, z_blogs: 1, z_credits: 0 });
        break;
      case "showHideCredits":
        this.setState({ showHideCredits: !this.state.showHideCredits, z_skills: 0, z_projects: 0, z_about: 0, z_blogs: 0, z_credits: 1 });
        break;
      default:
        break;
    }
  }

   handleCallback = (childData, name) =>{
      switch (name) {
        case "showHideSkills":
          this.setState({showHideSkills: childData});
          break;
        case "showHideProjects":
          this.setState({showHideProjects: childData});
          break;
        case "showHideAbout":
          this.setState({showHideAbout: childData});
          break;
        case "showHideBlogs":
          this.setState({showHideBlogs: childData});
          break;
        case "showHideCredits":
          this.setState({showHideCredits: childData});
          break;
        default:
          break;
        }
    }

    MItem(mimage, mname) {
      return(
        <VStack
          w='100%'
          h='100%'
        >
        <HStack
          w='100%' 
        >
          <Image
            w='50px'
            h='50px'
            src={mimage}
          />
          <Text
            fontSize='16px'
            fontFamily='BasedPixel'
            color='black'
          >
            {mname}
          </Text>
        </HStack>
        </VStack>
      );
    }

    handleCallbackTwo = (val_skills, val_projects, val_about, val_blogs, val_credits) => {
      this.setState({z_skills: val_skills, z_projects: val_projects, z_about: val_about, z_blogs: val_blogs, z_credits: val_credits});
    }

    handleClickOutside(event) {
      if(this.state.showMenu === true) {
        if(this.state.refMenu && !this.state.refMenu.current.contains(event.target) &&
          this.state.refMenuCard && !this.state.refMenuCard.current.contains(event.target)
        ) {
          this.setState({showMenu: false});
        }
      }
    };

    /* 

    componentDidMount() {
    //setTimeOut(() => this.setState({ loading: false}), 6000)

    //handle loading screen
    this.setState({isLoading: false});

    //handle outside click listener
    document.addEventListener("mousedown", this.handleClickOutside);

    // THREE JS SCENE
    var scene = new THREE.Scene();
    var cameraCenter = new THREE.Vector3();
    var cameraHorzLimit = 3;
    var cameraVertLimit = 3;
    var mouse = new THREE.Vector2();
      scene.background = new THREE.Color( 0x23272a );

      var camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 500);
      camera.position.set(0, 20, 200);
      camera.lookAt(scene.position);

      var renderer = new THREE.WebGLRenderer( { antialias: true } );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      // renderer.gammaOutput = true;
      // renderer.physicallyCorrectLights = true;
      // renderer.setPixelRatio( window.devicePixelRatio );
      // renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(renderer.domElement);

      //NAME 
      // const geometry = new THREE.PlaneGeometry( window.innerWidth, 25 );
      // const material = new THREE.MeshBasicMaterial({color: 0x3b4145, side: THREE.DoubleSide});
      // const plane = new THREE.Mesh( geometry, material );
      // plane.position.set(0,15,-100);
      //scene.add( plane );

      // const logoColor = 0x0a7b7f;

      // const geometryLogo = new THREE.PlaneGeometry();
      // const materialLogo = new THREE.MeshBasicMaterial({
      //   alphaMap: new THREE.TextureLoader().load('logo-thin.svg'),
      //   transparent: true,
      //   opacity: 1,
      //   color: logoColor,
      //   side: THREE.DoubleSide,
      // });

      // const plane = new THREE.Mesh(geometryLogo, materialLogo);
      // // @TODO animate this!
      // plane.position.set(0,15,-100);
      // scene.add( plane );

      //set up mouse stuff
      document.addEventListener('mousemove', onDocumentMouseMove, false);
      //window.addEventListener('resize', window.innerWidth / window.innerHeight, false);

      //GRIDS
      const hex = 0xF2F2F2;
      const color = new THREE.Color(hex);
      const size = 8;
      const distance = 350;
      
      //var division = 200;
      //var limit = 1000;

      //var grid_two = new THREE.GridHelper(limit * 2, division, "grey", "grey");

      // var moveable = [];
      // for (let i = 0; i <= division; i++) {
      //   moveable.push(1, 1, 0, 0); // move horizontal lines only (1 - point is moveable)
      // }

      //grid.geometry.setAttribute('moveable', new THREE.BufferAttribute(new Uint8Array(moveable), 1));
      
      const gridgeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
      const gridmaterial = new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
  
        uniforms: {
          uTime: {
            value: 0,
          },
          uSize: {
            value: size,
          },
          uColor: {
            value: color,
          },
          uDistance: {
            value: distance,
          },
        },
        transparent: true,
        vertexShader: `
        varying vec3 worldPosition;
        uniform float uDistance;
        void main() {
          vec3 pos = position.xzy * uDistance;
          pos.xz += cameraPosition.xz;
          worldPosition = pos;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }`,
  
        fragmentShader: `
        varying vec3 worldPosition;
        uniform float uSize;
        uniform float uTime;
        uniform vec3 uColor;
        uniform float uDistance;
        float getGrid(float size) {
          vec2 r = worldPosition.xz / size;
          vec2 gridOne = abs(fract(r - 0.5) - 0.5) / fwidth(r);
          vec2 gridTwo = abs(fract(r - uTime) - 0.5) / fwidth(r);
          float line = min(gridOne.x, gridTwo.y);
          return 1.0 - min(line, 1.0);
        }
        void main() {
          float d = 1.0 - min(distance(cameraPosition.xz, worldPosition.xz) / uDistance, 1.0);
          float grid = getGrid(uSize);
          float alpha = grid * pow(d, 3.0);
          gl_FragColor = vec4(uColor.rgb, alpha);
          gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, grid);
          if ( gl_FragColor.a <= 0.0 ) discard;
        }`,
  
        extensions: {
          derivatives: true,
        },
      });

      const grid = new THREE.Mesh(gridgeometry, gridmaterial);
      const grid_two = new THREE.Mesh(gridgeometry, gridmaterial);

      // grid.material = new THREE.ShaderMaterial({
      //   uniforms: {
      //     time: {
      //       value: 0
      //     },
      //     limits: {
      //       value: new THREE.Vector2(-limit, limit)
      //     },
      //     speed: {
      //       value: 5
      //     }
      //   },
      //   transparent: true,
      //   vertexShader: `
      //     uniform float time;
      //     uniform vec2 limits;
      //     uniform float speed;
          
      //     attribute float moveable;
          
      //     varying vec3 vColor;
        
      //     void main() {
      //       vColor = color;
      //       float limLen = limits.y - limits.x;
      //       vec3 pos = position;
      //       if (floor(moveable + 0.5) > 0.5){ // if a point has "moveable" attribute = 1 
      //         float dist = speed * time;
      //         float currPos = mod((pos.z + dist) - limits.x, limLen) + limits.x;
      //         pos.z = currPos;
      //       } 
      //       gl_Position = projectionMatrix * modelViewMatrix * vec4(pos,1.0);
      //     }
      //   `,
      //   fragmentShader: `
      //     varying vec3 vColor;
        
      //     void main() {
      //       gl_FragColor = vec4(vColor, 1.);
      //     }
      //   `,
      //   vertexColors: THREE.VertexColors
      // });


      // FOR SECOND GRID //

      
      // var moveable_two = [];
      // for (let i = 0; i <= division; i++) {
      //   moveable_two.push(1, 1, 0, 0); // move horizontal lines only (1 - point is moveable)
      // }

      // grid_two.geometry.setAttribute('moveable_two', new THREE.BufferAttribute(new Uint8Array(moveable_two), 1));
      // grid_two.material = new THREE.ShaderMaterial({
      //   uniforms: {
      //     time: {
      //       value: 0
      //     },
      //     limits: {
      //       value: new THREE.Vector2(-limit, limit)
      //     },
      //     speed: {
      //       value: 5
      //     }
      //   },
      //   vertexShader: `
      //     uniform float time;
      //     uniform vec2 limits;
      //     uniform float speed;
          
      //     attribute float moveable_two;
          
      //     varying vec3 vColor;
        
      //     void main() {
      //       vColor = color;
      //       float limLen = limits.y - limits.x;
      //       vec3 pos = position;
      //       if (floor(moveable_two + 0.5) > 0.5){ // if a point has "moveable_two" attribute = 1 
      //         float dist = speed * time;
      //         float currPos = mod((pos.z + dist) - limits.x, limLen) + limits.x;
      //         pos.z = currPos;
      //       } 
      //       gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      //     }
      //   `,
      //   fragmentShader: `
      //     varying vec3 vColor;
        
      //     void main() {
      //       gl_FragColor = vec4(vColor, 1.0);
      //     }
      //   `,
      //   vertexColors: THREE.VertexColors
      // });  

      grid_two.position.set(0, 30, 0);

      scene.add(grid);
      //console.log(grid.position);
      scene.add(grid_two);
      //console.log(grid_two.position);

      var clock = new THREE.Clock();
      let time = 0;

      //CUBE - 
      var cube = new THREE.Mesh(
        new THREE.BoxGeometry( 12, 12, 12 ),
        [
          new THREE.MeshBasicMaterial( { color: 0x80BCBD }),
          new THREE.MeshBasicMaterial( { color: 0xAAD9BB }),
          new THREE.MeshBasicMaterial( { color: 0xD5F0C1 }),
          new THREE.MeshBasicMaterial( { color: 0xF9F7C9 }),
          new THREE.MeshBasicMaterial( { color: 0xD9EDBF }),
          new THREE.MeshBasicMaterial( { color: 0xDCFFB7 }),
        ]
      );	
      scene.add( cube );
      cube.position.set(0, 15, 125);

      render();

      function render() {
        cube.rotation.x += 0.01;
	      cube.rotation.y += 0.01;

        requestAnimationFrame(render);
        updateCamera();
        time += clock.getDelta();
        grid.material.uniforms.uTime.value = time;
        grid_two.material.uniforms.uTime.value = time;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.render(scene, camera);
      }

      function updateCamera() {
        //offset the camera x/y based on the mouse's position in the window
        camera.position.x = cameraCenter.x + (cameraHorzLimit * mouse.x);
        camera.position.y = cameraCenter.y + 15 + (cameraVertLimit * mouse.y);
      }
      
      function onDocumentMouseMove(event) {
        event.preventDefault();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      }

      if (window.DeviceOrientationEvent) {
          window.addEventListener('deviceorientation', function (eventData) {      

            const beta = Math.max(Math.min(eventData.beta, 120), 0);
            const yPos = (beta - 45) / 20; // -180, 180
            if (beta > 85 && beta < 95) return;
            const xPos = eventData.gamma / 20; // -90, 90
            
            //camera.position.set(xPos, -yPos, 100);
            // camera.lookAt(new THREE.Vector3(0, 0, 0));
            // plane.lookAt(camera.position);

            // var tiltX = Math.round(eventData.gamma * 2 );  
            // var tiltY =  Math.round(eventData.beta * 2);
            mouse.x = xPos  //(Math.round(eventData.gamma) / window.innerWidth) * 2 - 1;  
            mouse.y = -yPos //-(Math.round(eventData.beta) / window.innerHeight) * 2 + 1;
    
            //deviceOrientationHandler(xPos,-yPos);
          }, false);
      }
      
      // function deviceOrientationHandler(tiltX, tiltY){
      //   mouse.x = tiltX;
      //   mouse.y = tiltY;
      // }

    }

    */

    componentDidMount() {
      // Start the clock interval
      this.clockInterval = setInterval(this.updateClock, 1000);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      clearInterval(this.clockInterval);
    }

    updateClock = () => {
      this.setState({ currentTime: new Date() });
    };

  render() {
    const { loadingComplete, currentTime, showHideSkills, showHideProjects, showHideAbout, showHideBlogs, showHideCredits, z_skills, z_about, z_projects, z_blogs, z_credits } = this.state;
     
    return (
    
    <div>
      {!loadingComplete ? (
        <Loading onLoadingComplete={this.handleLoadingComplete} />
      ) : (
        <div className="App" >
          <div ref={ref => (this.mount = ref)} /> 

              <div class="nav">
                <Wrap 
                  direction='column'
                  paddingInline='20px'
                >
                  {/* <li>
                    <button id="skills_btn" onClick={() => this.hideComponent("showHideSkills")}>
                      <img src={logo_skills} alt="SKILLS" />
                      <b>SKILLS</b>
                    </button>
                  </li>
                  <li>
                    <button id="projects_btn" onClick={() => this.hideComponent("showHideProjects")}><img src={logo_project} alt="PROJECTS" /></button>
                  </li>
                  <li>
                    <button id="contact_btn" onClick={() => this.hideComponent("showHideAbout")}><img src={logo_contact} alt="CONTACT" /></button>
                  </li>
                  <li>
                    <button id="blogs_btn" onClick={() => this.hideComponent("showHideBlogs")}><img src={logo_blogs} alt="BLOGS" /></button>
                  </li> */}
                  <WrapItem>
                    <Box onClick={() => this.hideComponent("showHideSkills")}>
                      <Tooltip 
                        label="ABOUT" aria-label='A tooltip'
                        fontSize='2xl' fontFamily='BasedPixel'
                        color='white' placement='right-start'>
                        <Image src={logo_skills} alt="SKILLS" w={{base: 75, md: 100}} h={{base: 75, md: 100}} />
                      </Tooltip>
                    </Box>
                  </WrapItem>
                  <WrapItem>
                    <Box onClick={() => this.hideComponent("showHideProjects")}>
                      <Tooltip 
                        label="PROJECTS" aria-label='A tooltip'
                        fontSize='2xl' fontFamily='BasedPixel'
                        color='white' placement='right-start'>
                        <Image src={logo_project} alt="PROJECTS" />
                      </Tooltip>
                    </Box>
                  </WrapItem>
                  <WrapItem>
                    <Box onClick={() => this.hideComponent("showHideAbout")}>
                      <Tooltip 
                        label="CONTACT" aria-label='A tooltip'
                        fontSize='2xl' fontFamily='BasedPixel'
                        color='white' placement='right-start'>
                        <img src={logo_contact} alt="CONTACT" />
                      </Tooltip>
                    </Box>
                  </WrapItem>
                  <WrapItem>
                    <Box onClick={() => this.hideComponent("showHideBlogs")}>
                      <Tooltip 
                        label="BLOGS" aria-label='A tooltip'
                        fontSize='2xl' fontFamily='BasedPixel'
                        color='white' placement='right-start'>
                        <img src={logo_blogs} alt="BLOGS" />
                      </Tooltip>
                    </Box>
                  </WrapItem>
                </Wrap>
              </div>
                  {showHideSkills && <Skills parentCallback = {this.handleCallback} data={z_skills} parentCallbackTwo={this.handleCallbackTwo} />}
                  {showHideProjects && <Projects parentCallback = {this.handleCallback} data={z_projects} parentCallbackTwo={this.handleCallbackTwo}/>}
                  {showHideAbout && <About parentCallback = {this.handleCallback} data={z_about} parentCallbackTwo={this.handleCallbackTwo} />}
                  {showHideBlogs && <Blogs parentCallback = {this.handleCallback} data={z_blogs} parentCallbackTwo={this.handleCallbackTwo} />}
                  {showHideCredits && <Credits parentCallback = {this.handleCallback} data={z_credits} parentCallbackTwo={this.handleCallbackTwo} />}
              {/* <div className="footer">
                {/* <p><b>***  The owner is a really low functioning lazy individual until equiped with caffeine ***</b></p> 
                <marquee behavior="scroll" direction="left" scrollamount="8"><b>***  The owner is a really low functioning lazy individual until equiped with caffeine ***</b></marquee>
              </div>
              <div id="clock"></div> */}
              <Grid
                templateColumns='repeat(22, 1fr)'
                w='100%'
                h='25px'
                bg='grey'
                border='1px solid black'
                color='black'
                position='fixed'
                bottom='0'
                zIndex={2}
              >
                <GridItem
                  colSpan={1}
                  minW='60px'
                  ref={this.state.refMenu}
                >
                  <Center
                    w='100%'
                    h='100%'
                  >
                    <Box
                      w='100%'
                      h='100%'
                      border='1px solid black'
                      onClick={() => {
                        this.setState({showMenu: !this.state.showMenu });
                      }}
                      _hover={{
                        color: '#ffffff',
                      }}
                      _active={{
                        color: '#00bfa588'
                      }}
                    >
                      <Center
                        w='100%'
                        h='100%'
                      >
                      <Text
                        fontSize='18px'
                        fontFamily='BasedPixel'
                        mt='0'
                      >
                        MENU
                      </Text>
                      </Center>
                    </Box>
                  </Center>
                </GridItem>
                <GridItem
                  colSpan={20}
                  fontFamily='BasedPixel'
                  fontSize='16px'
                >
                  <marquee behavior="scroll" direction="left" scrollamount="6">*****  The owner is a really low functioning lazy individual until equiped with caffeine *****</marquee>
                </GridItem>
                <GridItem
                  colSpan={1}
                  minWidth='110px'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Center
                    w='100%'
                    h='100%'
                  >
                <Box
                  // as="div"
                  // id='clock'
                  textAlign='center'
                  fontFamily='BasedPixel'
                  fontWeight='bold'
                  fontSize='18px'
                  w='100%'
                  h='100%'
                  border='1px solid black'
                >{currentTime.toLocaleTimeString([], {hour12: false})}</Box>
                </Center>
                </GridItem>
              </Grid>
              {!this.state.showMenu ? (
                <></>
              ) : (
                <Box
                  w='150px'
                  h='320px'
                  bg='gray'
                  bottom='27px'
                  left='0'
                  position='absolute'
                  border='1px solid black'
                  borderBottom='0px'
                  borderLeft='2px solid black'
                  ref={this.state.refMenuCard}
                  zIndex={3}
                >
                  <Grid
                    templateColumns='repeat(6, 1fr)'
                    templateRows='repeat(4, 1fr)'
                    h='100%'
                    w='100%'
                  >
                    <GridItem
                      colSpan={1}
                      rowSpan={4}
                      h='100%'
                      w='100%'
                      bg='linear-gradient(0deg, #009688, gray)'
                    >
                      <Center
                        w='100%'
                        h='100%'
                      >
                        <Text
                          sx={{writingMode: 'vertical-rl'}}
                          transform='rotate(180deg)'
                          fontSize='16px'
                          fontFamily='BasedPixel'
                          color='white'
                          position='absolute'
                          bottom='10'
                          letterSpacing='-2%'
                        > 
                          THYME 
                        </Text>
                        <Text
                          sx={{writingMode: 'vertical-rl'}}
                          transform='rotate(180deg)'
                          fontSize='13px'
                          fontFamily='BasedPixel'
                          color='white'
                          position='absolute'
                          bottom='60'
                          letterSpacing='-5%'
                        > 
                          PERFORMANCE v2.0
                        </Text>
                      </Center>
                    </GridItem>
                    <GridItem
                      colSpan={5}
                      rowSpan={3}
                    >
                      <VStack
                        mt='8px'
                      >
                        <Box
                          w='100%'
                          h='40px'
                          display='flex'
                          _hover={{
                            bg: '#00968880',
                            color: 'white'
                          }}
                          onClick={() => {
                            this.hideComponent("showHideSkills")
                            this.setState({showMenu: false});
                          }}
                        >
                          <Box w='40px' h='40px' >
                            <Center h='100%' w='100%'>
                              <Image w='40px' h='40px' src={logo_skills} />
                            </Center>
                          </Box>
                          <Box w='calc(100%-40px)' h='40px'>
                            <Center w='100%' h='100%'>
                              <Text paddingInline='5px' fontSize='16px' fontFamily='BasedPixel'>ABOUT</Text>
                            </Center>
                          </Box>
                        </Box>
                        <Box
                          w='100%'
                          h='40px'
                          display='flex'
                          _hover={{
                            bg: '#00BFA580',
                            color: 'white'
                          }}
                          onClick={() => {
                            this.hideComponent("showHideProjects")
                            this.setState({showMenu: false});
                          }}
                        >
                          <Box w='40px' h='40px' >
                            <Center h='100%' w='100%'>
                              <Image w='40px' h='40px' src={logo_project} />
                            </Center>
                          </Box>
                          <Box w='calc(100%-40px)' h='40px'>
                            <Center h='100%'>
                              <Text paddingInline='5px' fontSize='16px' fontFamily='BasedPixel'>PROJECTS</Text>
                            </Center>
                          </Box>
                        </Box>
                        <Box
                          w='100%'
                          h='40px'
                          display='flex'
                          _hover={{
                            bg: '#00968880',
                            color: 'white'
                          }}
                          onClick={() => {
                            this.hideComponent("showHideAbout");
                            this.setState({showMenu: false});
                          }}
                        >
                          <Box w='40px' h='40px' >
                            <Center h='100%' w='100%'>
                              <Image w='40px' h='40px' src={logo_contact} />
                            </Center>
                          </Box>
                          <Box w='calc(100%-40px)' h='40px'>
                            <Center h='100%'>
                              <Text paddingInline='5px' fontSize='16px' fontFamily='BasedPixel'>CONTACT</Text>
                            </Center>
                          </Box>
                        </Box>
                        <Box
                          w='100%'
                          h='40px'
                          display='flex'
                          _hover={{
                            bg: '#00968880',
                            color: 'white'
                          }}
                          onClick={() => {
                            this.hideComponent("showHideBlogs")
                            this.setState({showMenu: false});
                          }}
                        >
                          <Box w='40px' h='40px' >
                            <Center h='100%' w='100%'>
                              <Image w='40px' h='40px' src={logo_blogs} />
                            </Center>
                          </Box>
                          <Box w='calc(100%-40px)' h='40px'>
                            <Center h='100%'>
                              <Text paddingInline='5px' fontSize='16px' fontFamily='BasedPixel'>BLOGS</Text>
                            </Center>
                          </Box>
                        </Box>
                      </VStack>
                    </GridItem>
                    <GridItem
                      colSpan={5}
                      rowSpan={1}
                      borderTop='1px solid black'
                    >
                    <Box
                          w='100%'
                          h='40px'
                          display='flex'
                          _hover={{
                            bg: '#00968880',
                            color: 'white'
                          }}
                          onClick={() => {
                            this.hideComponent("showHideCredits")
                            this.setState({showMenu: false});
                          }}
                        >
                          <Box w='calc(100%-40px)' h='40px'>
                            <Center h='100%'>
                              <Text paddingInline='5px' fontSize='16px' fontFamily='BasedPixel'>CREDITS</Text>
                            </Center>
                          </Box>
                        </Box> 
                    </GridItem>
                  </Grid>
                </Box>
              )}
        </div>
      )}
    </div>
    )
  }
}


export default App;

//TODO: Add screen rotation (for mobile)
//TODO: Add mobile gyro :DONE
//TODO: UI bug fixes - Fucking list them down!
//TODO: Add loading screen

/* 

  FUTURE IDEAS:
      1. Create an THYM-LLM chat app
        1.1 something that talks like me?
        1.2 something that given a code file / repo - given problem - will generate code according
      2. Create new Window for TRASH
        2.1 Store ideas will work on future
        2.2 Card format when clicked shows detailed idea
      3. 

*/
