import React, { useEffect, useState } from "react";
import Draggable from 'react-draggable';
import { request, GraphQLClient, gql } from "graphql-request";
import { 
  Text, Box, Image, WrapItem, Hide,
  HStack, VStack, Grid, GridItem, Stack, Flex,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router, Routes, Route,
  Link, useParams
} from "react-router-dom";
import { RichText } from "@graphcms/rich-text-react-renderer";

import './blogs.css'
import ArrowLeft from "../assets/icons/arrow.svg"
import BlogTemplate from "./BlogTemplate.jsx";
import BlogPosts from "./BlogTemplate.jsx";
import warning_img from "../assets/images/warning_img.png";

const graphcms =
    "https://api-ap-south-1.graphcms.com/v2/cl4mpujwl7y9a01xv80jxacfs/master";

const QUERY = gql`
{
    posts {
    id,
    title,
    description,
    readTime,
    slug,
    content {
      raw,
    }
    author {
      id
    },
    coverPhoto {
      url
    },
    datePublished,
    tags
  },
}
`


// class Blogs extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "blogs",
//       posts: [],
//     };
//   }

//   componentDidMount() {
//     request(graphcms, QUERY).then((data) => this.setState({posts: data}))
//   }

//   onTrigger = (event) => {
//     this.props.parentCallback(false, "showHideBlogs");
//     event.preventDefault();
//   }

//   onTriggerMax = (event) => {
    
//   }

//   onTriggerTwo = (event) => {
//     this.props.parentCallbackTwo(0, 0, 0, 1);
//     event.preventDefault();
//   }

//   render() {
//     console.log(this.state.posts);
//     return (
//       <Draggable handle="#titlebar" bounds="body">

//         <div id="blogs_window" onClick={this.onTriggerTwo} style={{zIndex: this.props.data}}>

//           <header id="titlebar">

//             <div id="window-title">
//               <span><b>Blogs.exe</b></span>
//             </div>

//             <div id="window-controls">
//               <div className="button" id="max-button" title="Maximize" onClick={this.onTriggerMax}>
//               </div>
//               <div className="button" id="close-button" title="Close" onClick={this.onTrigger}>
//               </div>
//             </div>

//           </header>

//           <div id="content_blogs">
//             <Router>
//                 {!this.state.posts ? (
//                 'Loading'
//                 ) : (
//                 <React.Fragment>
//                     <ul>
//                     { this.state.posts.map(({ title, slug }) => (
//                         <li key={title}>
//                             <Link to={`/blogs/${slug}`}>{title}</Link>
//                         </li>
//                     ))}
//                     </ul>
//                     {/* <Switch>
//                     <Route path="/blogs/:slug">
//                         <Product products={products} />
//                     </Route>
//                     </Switch> */}
//                 </React.Fragment>
//                 )}
//             </Router>
//           </div>
//         </div>
//       </Draggable>
//     );
//   }
// }

const BlogCards = (posts) => {
  return (
    <Box
      key={posts.id}
      w='100%'
      h='40px'
      bg='tomato'
      color='black'
    >
      {posts.title}
    </Box>
  );
}

function Child() {
  let { id } = useParams();
  return (
    <div>
      <h1>{id}</h1>
    </div>
  );
}

const calculateTimeAgo = (date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  const seconds = Math.floor((currentDate - inputDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
  }
};

const calculateTimeNow = (dateString) => {
  return new Date(dateString).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

const Blogs = (props) => {
  const[window, setWindow] = useState('windowed');
  const[isMax, setMax] = useState(false);
  const [x, setX]= useState(0);
  const [y, setY]= useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [originalY, setOriginalY] = useState(0);

  const [posts, setPosts] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);

  const pFontSizeBreakpoint = {base: '1rem', md: '1.35rem'};

  useEffect(() => {
      const fetchPosts = async () => {
        const { posts } = await request(
              graphcms, QUERY
          );
        setPosts(posts);
      };
  
      fetchPosts();
  }, []);

  function onTrigger(event) {
    props.parentCallback(false, "showHideBlogs");
    event.preventDefault();
  }

  function onTriggerMax(event) {
    if(isMax === false) {
      setX(0);
      setY(0);
      setOriginalX(x);
      setOriginalY(y);
      setWindow('fullscreen');
      setMax(true);
    } else {
      setWindow('windowed');
      setX(originalX);
      setY(originalY);
      setMax(false);
    }
    event.preventDefault();
  }

  function onTriggerTwo(event) {
    props.parentCallbackTwo(0, 0, 0, 1, 0);
    event.preventDefault();
  }

  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
    event.preventDefault();
  };

  return (
      <Draggable 
        handle="#titlebar"
        bounds="body"
        cancel="#window-controls"
        onStop={handleStop} 
        position={{x: x, y: y}}
      >

        <div id="blogs_window" className={window} onClick={onTriggerTwo} style={{zIndex: props.data, transition: 'all 0.2s ease'}}>

          <header id="titlebar">

            <div id="window-title">
            <span><b>BLOGS</b></span>
            </div>

            <div id="window-controls">
            <div className="button" id="max-button" title="Maximize" onClick={onTriggerMax}>
            </div>
            <div className="button" id="close-button" title="Close" onClick={onTrigger}>
            </div>
            </div>

          </header>

          <Box
            w='100%' mt='30px' h='calc(100% - 30px)'
            bg='#0F1117' color='white' overflowY='scroll'
            sx={
              { 
              '::-webkit-scrollbar':{
                    display:'none'
                }
              }
            }
          >

            <Router
              w='100%'
            >
              {!posts ? (
                <Stack align='center' justifyContent='center' mt='2%' spacing={20}>
                </Stack>
              ) : !currentPost ? (
                <VStack
                  spacing='20px' p='15px' w='calc(100% - 50px)'
                  alignItems='start'
                >
                  <Text
                    fontFamily='WotfardSemi' fontSize='20px'
                    color='#bbc1d3' paddingStart={20} m={0} mt={10}
                  >
                    {posts.length} Blog Posts 📝
                  </Text>
                  {posts.map((post) => (
                    // <Link key={id} to={`/blogs/${slug}`} style={{ textDecoration: 'none', width: '100%' }}>
                      <Grid h='fit-content' w='100%'
                        border='0.1rem solid' borderColor='#1e2024' borderRadius='6px'
                        templateColumns='repeat(7, 1fr)' templateRows='repeat(5, 0.8fr)'
                        onClick={() => {
                          setCurrentPost(post);
                        }}
                      >
                        <GridItem colSpan={6} rowSpan={4} p={12}>
                          <Text
                            fontSize='1.125rem' fontWeight='bold'
                            fontFamily='Roboto, sans-serif'
                            color='#e9ebf1' marginBlock={10}
                            lineHeight='1.25rem'
                          >{post.title}</Text>
                          <Text
                            fontSize='0.95rem' fontWeight='normal'
                            fontFamily='Roboto, sans-serif'
                            color='#6e7ba0' marginBlock={10}
                            lineHeight='1.05rem'
                          >{post.description}</Text>
                          <Text
                            fontSize='0.75rem' fontWeight='normal'
                            fontFamily='Roboto, sans-serif' marginBlock={8}
                            color='#9fa8c1'
                            lineHeight='0.75rem'
                          >{calculateTimeAgo(post.datePublished)}</Text>
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={5}>
                          <Hide breakpoint='(max-width: 900px)'>
                            <Image objectFit='cover' borderRightRadius='6px'
                              src={post.coverPhoto.url}
                              w='200px' h='100%'
                            />
                          </Hide>
                        </GridItem>
                        <GridItem
                          colSpan={6} rowSpan={1}
                          borderTop='1px solid' borderColor='#1e2024'
                        >
                          <HStack>
                            <Text
                              fontSize='0.75rem' fontWeight='normal'
                              fontFamily='Roboto, sans-serif'
                              color='#9fa8c1' ps={12}
                              lineHeight='0.75rem'
                            >{post.readTime} min read</Text>
                            {post.tags.map((data, index) =>(
                              <WrapItem key={index}>
                                <Text
                                  m={0}
                                  fontSize='0.75rem' fontFamily='Roboto, sans-serif'
                                  color='#717684' lineHeight='0.75rem' paddingInline={4}
                                >{data}</Text>
                              </WrapItem>
                            ))}
                          </HStack>
                        </GridItem>
                      </Grid>
                    // </Link>
                  ))}
                  {/* <Switch>
                      <Route path="/blogs/:slug">
                          <Product products={products} />
                      </Route>
                  </Switch> */}
                </VStack>
              ) : (
                <VStack
                  position="relative"
                    height="400px"
                    background="linear-gradient(to bottom, rgba(15, 17, 23, 1), rgba(15, 17, 23, 0.5)), repeating-linear-gradient(0deg, #555, #555 1px, transparent 1px, transparent 30px), repeating-linear-gradient(90deg, #555, #555 1px, transparent 1px, transparent 30px)"
                    _before={{
                      content: '""',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(to bottom, rgba(15, 17, 23, 0), rgba(15, 17, 23, 1))',
                    }}
                >
                  <Box position="relative" zIndex="2" align='center' paddingBottom={120} paddingTop={40} paddingInline={40}>
                  <HStack
                    w='100%' align='center' mb={30} alignSelf='flex-start'
                    justifyContent={'space-between'}
                    onClick={() => {
                      setCurrentPost(null);
                    }}
                  >
                    <HStack w='fit' h='auto' ml={20} border='1px solid #333' rounded='8px' spacing={0} bg='#12151c'>
                      <Image
                        paddingStart={10} objectFit='cover' w={20} h={'auto'} src={ArrowLeft}
                      />
                      <Text
                        fontSize={'1rem'} paddingInline={18} paddingBlock={4} fontWeight='medium'
                        fontFamily='Roboto, sans-serif' lineHeight={'2px'}
                      >
                        Back
                      </Text>
                    </HStack>
                    <Text
                      fontSize='0.95rem' fontWeight='normal' alignSelf='start'
                      fontFamily='Roboto, sans-serif' lineHeight='1.25rem'
                      color='#9FA8C1' paddingBottom={[0, 20]} paddingInline={80}
                    >
                      {currentPost.readTime} min read
                    </Text>
                  </HStack>

                  <Text
                    fontSize={['1rem', '1rem', "1.75rem", "2.5rem"]} fontWeight='bold'
                    fontFamily='Roboto, sans-serif' lineHeight={'3rem'}
                    color='#e9ebf1' textAlign='center' paddingBottom={[0, 40]} paddingInline={[80]}
                  >{currentPost.title}</Text>
                  
                  <Image objectFit='cover' borderRadius='6px'
                    src={currentPost.coverPhoto.url}
                    w='75%' h='auto'
                  />

                  <Text
                    fontSize='1rem' fontWeight='normal'
                    fontFamily='Roboto, sans-serif' lineHeight='1.25rem'
                    color='#9FA8C1' paddingBlock={20} paddingInline={80}
                  >{currentPost.description}</Text>

                  <Flex w='90%' align='center' justifyContent='space-between' flexDirection='row'>
                    <HStack>
                    {currentPost.tags.map((data, index) =>(
                      <WrapItem key={index}>
                        <Text
                          m={0} p={10} border='1px solid #222222' borderRadius={6}
                          fontSize='0.95rem' fontFamily='Roboto, sans-serif'
                          color='#e9ebf1' lineHeight='1.25rem' paddingInline={20}
                        >{data}</Text>
                      </WrapItem>
                    ))}
                    </HStack>
                    <Text
                      fontSize='0.9rem' fontWeight='normal'
                      fontFamily='Roboto, sans-serif' lineHeight='1.25rem'
                      color='#9FA8C1'
                    > Published 
                    <Text as='span' paddingStart={8} color='#e9ebf1'>
                    {calculateTimeNow(currentPost.datePublished)}</Text>
                    </Text>
                  </Flex>

                  <VStack pt={40} w={['80%', '80%', '60%', '60%']} 
                    spacing={20} align='start' textAlign='start'
                  >
                    <RichText
                      content={currentPost.content.raw}
                      renderers={{
                        h1: ({ children }) => <Text fontSize="2.5rem" fontWeight='bold' 
                        fontFamily='Roboto, sans-serif' lineHeight='3rem' color='#e9ebf1'
                        paddingBlock={20} 
                        >{children}</Text>,
                        h2: ({ children }) => <Text fontSize='2rem' fontWeight='bold' 
                        fontFamily='Roboto, sans-serif' lineHeight='3rem' color='#e9ebf1'
                        paddingBlock={20} 
                        >{children}</Text>,
                        p: ({ children }) => <Text
                          fontSize='1.35rem' fontWeight='normal'
                          fontFamily='Roboto, sans-serif'
                          color='#dadde7' lineHeight='2.5rem'
                        >{children}</Text>,
                        a: ({ children, href, openInNewTab }) => (
                          <a
                            href={href}
                            target={openInNewTab ? '_blank' : '_self'}
                            style={{ color: 'green' }}
                            rel="noreferrer"
                          >
                            {children}
                          </a>
                        ),
                        bold: ({ children }) => <strong>{children}</strong>,
                        Asset: {
                          text: () => (
                            <div>
                              <p>text plain</p>
                            </div>
                          ),
                        },
                      }}
                    />
                  </VStack>
                  </Box>
                </VStack>
              )}
            </Router>

          </Box>
        </div>
      </Draggable>
  );

}

export default Blogs;
