import React, { useState } from "react";
import axios from 'axios';
import { 
  Grid,
  GridItem,
  Center,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  useToast,
 } from "@chakra-ui/react";
import Draggable from 'react-draggable';
import "./contact.css";

// class About extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',
//       email: '',
//       message: ''
//     };
//   }

//   onTrigger = (event) => {
//     this.props.parentCallback(false, "showHideAbout");
//     event.preventDefault();
//   }

//   onTriggerMail = (event) => {
//     console.log('clicked');
//     //console.log(this.state);
//     window.open('mailto:patildhiraj2306@gmail.com?subject='+this.state.name+'&body='+this.state.message);
//   }

//   onTriggerTwo = (event) => {
//     this.props.parentCallbackTwo(0, 0, 1, 0);
//     event.preventDefault();
//   }

//   onNameChange(event) {
//     this.setState({name: event.target.value})
//   }

//   onEmailChange(event) {
//     this.setState({email: event.target.value})
//   }

//   onMessageChange(event) {
//     this.setState({message: event.target.value})
//   }

//   render() {
//     return (
//       <Draggable handle="#titlebar" bounds="body"> 

//         <div id="contact_window" onClick={this.onTriggerTwo} style={{zIndex: this.props.data}}>

//           <header id="titlebar">

//             <div id="window-title">
//               <span><b>Contact.exe</b></span>
//             </div>

//             <div id="window-controls">
//               <div className="button" id="close-button" title="Close" onClick={this.onTrigger}>
//               </div>
//             </div>

//           </header>
          
//           <div id="contact-body">
//             <p>CONTACT FORM</p>

//             <form id="contact-form" method="POST">
//               <div className="form-group">
//                 <label htmlFor="name">Name</label>
//                 <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="exampleInputEmail1">Email address</label>
//                 <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="message">Message</label>
//                 <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
//               </div>
//                 <div className="form-group" id="mail-me" onClick={this.onTriggerMail}>
//                   <b>Mail me</b>
//                 </div>
//             </form>
          
//           </div>
        
//         </div>
      
//       </Draggable>
//     );
//   }
// }

const About = (props) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const[windowSize, setWindowSize] = useState('windowed');
  const[isMax, setMax] = useState(false);
  const [x, setX]= useState(0);
  const [y, setY]= useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [originalY, setOriginalY] = useState(0);

  const toast = useToast();

  const API_KEY = process.env.REACT_APP_MAILGUN_API_KEY;
  const DOMAIN_NAME = process.env.REACT_APP_MAILGUN_DOMAIN_NAME;

  function onTrigger(event) {
    props.parentCallback(false, "showHideAbout");
    event.preventDefault();
  }

  function onTriggerMax(event) {
    if(isMax === false) {
      setX(0);
      setY(0);
      setOriginalX(x);
      setOriginalY(y);
      setWindowSize('fullscreen');
      setMax(true);
    } else {
      setWindowSize('windowed');
      setX(originalX);
      setY(originalY);
      setMax(false);
    }
    event.preventDefault();
  }

  const onTriggerMail = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('from', 'mailgun@sandbox0188461716184cfda791339f767d30ac.mailgun.org');
    formData.append('to', 'dhiraj@dhiraj.xyz');
    formData.append('subject', `${name} | ${email}`);
    formData.append('text', message);

    try {
      await axios.post(`https://api.mailgun.net/v3/${DOMAIN_NAME}/messages`, formData, {
        auth: {
          username: 'api',
          password: `${API_KEY}`,
        },
      });

      toast({
        title: 'Email sent.',
        description: "We've sent your email successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEmail('');
      setName('');
      setMessage('');
    } catch (error) {
      toast({
        title: 'Error sending email.',
        description: "Please try again.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  function onTriggerTwo(event) {
    props.parentCallbackTwo(0, 0, 1, 0, 0);
    event.preventDefault();
  }

  function onNameChange(event) {
    setName(event.target.value);
  }

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onMessageChange(event) {
    setMessage(event.target.value);
  }

  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
    event.preventDefault();
  };

  return (
    <Draggable 
      handle="#titlebar"
      bounds="body"
      cancel="#window-controls"
      onStop={handleStop} 
      position={{x: x, y: y}}
    > 

      <div id="contact_window" className={windowSize} onClick={onTriggerTwo} style={{zIndex: props.data}}>

        <header id="titlebar">

          <div id="window-title">
            <span><b>CONTACT</b></span>
          </div>

          <div id="window-controls">
          <div className="button" id="max-button" title="Maximize" onClick={onTriggerMax}>
            </div>
            <div className="button" id="close-button" title="Close" onClick={onTrigger}>
            </div>
          </div>

        </header>

        <Grid h='calc(100% - 30px)' w='100%' mt='30px' templateRows='repeat(7, 1fr)' bg="rgb(23 23 23)" gap={2} >
          
          <GridItem  rowSpan={1} >
            <Text color='#FFFFFF' ps='2%' fontSize='2rem' fontWeight='bold' fontFamily='Roboto, sans-serif'>
              Contact me
              <Text color='rgb(163 163 163)' p={0}  fontSize='1rem' marginBlock={8} fontWeight='normal'>Just a hey - or anything interesting</Text>
            </Text>
          </GridItem>

          <GridItem rowSpan={6} >
            <Grid w='auto' h='100%' templateColumns='repeat(2, 1fr)' gap='10%' marginStart='2%'>
              
              <GridItem colSpan={1} fontFamily='Roboto, sans-serif' mt={40}>
                <HStack color='rgb(115 115 115)' alignItems='start' spacing={16}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z"/><path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10"/></svg>
                  <VStack alignItems='start'>
                    <Text m={0} fontSize='1rem' color='white' fontWeight='bold'>Email me:</Text>
                    <Text m={0} fontSize='1rem' color='rgb(163 163 163)' >dhiraj@dhiraj.xyz</Text>
                  </VStack>
                </HStack>

                <HStack color='rgb(115 115 115)' alignItems='start' spacing={16} mt={32}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="m3 11 18-5v12L3 14v-3z"/><path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"/></svg>
                  <VStack alignItems='start'>
                    <Text m={0} fontSize='1rem' color='white' fontWeight='bold'>Work with me:</Text>
                    <Text m={0} fontSize='1rem' color='rgb(163 163 163)' >Always on the lookout for new opportunities to create and innovate! <br/> Open source, freelance, startups.</Text>
                  </VStack>
                </HStack>

              </GridItem>

              <GridItem colSpan={1}
                overflowY='scroll'
                sx={
                    { 
                  '::-webkit-scrollbar':{
                          display:'none'
                      }
                  }
                }
              >
                <FormControl color='white' mr='50px' mt='40px' fontFamily='Roboto, sans-serif'>
                  <VStack alignItems='start' justifyContent='center' spacing={16}>

                    <Input
                      id='name' type='text' color='white' fontSize='16px' w='calc(80% - 24px)' borderRadius={8} border="none" p={12} bg='rgb(38 38 38)' focusBorderColor="transparent" placeholder="Name"
                      _focus={{
                        boxShadow: "none", 
                        outline: "none",
                        border: "none",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input 
                      id='email' type='email' color='white' fontSize='16px' w='calc(80% - 24px)' borderRadius={8} border="none" p={12} bg='rgb(38 38 38)' focusBorderColor="transparent" placeholder="Email"
                      _focus={{
                        boxShadow: "none", 
                        outline: "none",
                        border: "none",
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Textarea
                      id='message' type='textarea' w='80%' m={0} fontFamily='Roboto, sans-serif' color='white' fontSize='16px' borderRadius={8} border="none" p={12} bg='rgb(38 38 38)' focusBorderColor="transparent" placeholder="Message"
                      _focus={{
                        boxShadow: "none", 
                        outline: "none",
                        border: "none",
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}

                      sx={
                        { 
                      '::-webkit-scrollbar':{
                              display:'none'
                          }
                      }
                    }
                    />

                    <HStack onClick={onTriggerMail} alignItems='center' p={6} paddingEnd={12} bg='#00bfa5' borderRadius={16} color='rgb(38 38 38)' fontWeight='bold'>
                      <Text fontSize='1rem' paddingStart={12} m={0}>Send</Text>
                      <svg class="flex-shrink-0 size-4 transition group-hover:translate-x-0.5 group-hover:translate-x-0 group-focus:translate-x-0.5 group-focus:translate-x-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/><path d="m12 5 7 7-7 7"/></svg>
                    </HStack>

                  </VStack>

                </FormControl>
              </GridItem>

            </Grid>
          </GridItem>

        </Grid>
      
      </div>
    
    </Draggable>
  );
}


export default About;
